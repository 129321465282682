import { defineStore } from 'pinia'

export const useAssetStore = defineStore('assets', {
  state: () => {
    return {
      hiddenAssetIds: [],
      singleViewDashboardSetting: [],
      assetGroupByFilter: '',
      hiddenMapAssetIds: [],
      mapAssetGroupByFilter: '',
      hiddenReportAssetIds: [],
      reportAssetGroupByFilter: '',
      allAssets: [],
      isSingleAssetView: false,
      dashboardAssets: [],
      singleViewSelectedAssetID: null,
      assetsWithUnknownData: [],
    }
  },
  actions: {
    toggleAssetIdHidden(assetId) {
      const idx = this.hiddenAssetIds.indexOf(assetId)
      if (idx === -1) {
        this.hiddenAssetIds.push(assetId)
      } else {
        this.hiddenAssetIds.splice(idx, 1)
      }
    },
    //To handle Dashboard Assets
    hideAssetIds(assetIds) {
      this.hiddenAssetIds = this.hiddenAssetIds.filter(f => !assetIds.includes(f))
    },
    setHiddenAssetIds(assetIds) {
      this.hiddenAssetIds = assetIds
    },
    setSingleViewDashboardSetting(dashboard) {
      this.singleViewDashboardSetting = []
      this.singleViewDashboardSetting.push(dashboard)
    },
    setAssetGroupByFilter(option) {
      this.assetGroupByFilter = option
    },
    setSingleViewSelectedAssetID(assetId) {
      this.singleViewSelectedAssetID = assetId
    },
    singleSelectedAsset() {
      if (!this.isSingleAssetView) {
        return null
      }
      return this.allAssets.find(asset => asset.id === this.singleViewSelectedAssetID)
    },
    assetsSelected() {
      if (this.isSingleAssetView) {
        const selected = this.allAssets.find(asset => asset.id === this.singleViewSelectedAssetID)
        if (selected) {
          return [selected]
        }
        return []
      }
      return this.dashboardAssets?.filter(asset => !this.hiddenAssetIds.includes(asset.id))
    },
    getDashboardAssets() {
      return this.dashboardAssets
    },
    //To handle Map Assets Selector
    hideMapAssetIds(assetIds) {
      this.hiddenMapAssetIds = this.hiddenMapAssetIds.filter(f => !assetIds.includes(f))
    },
    setMapHiddenAssetIds(assetIds) {
      this.hiddenMapAssetIds = assetIds
    },
    setMapAssetGroupByFilter(option) {
      this.mapAssetGroupByFilter = option
    },
    assetsSelectedInMap() {
      return this.allAssets?.filter(asset => !this.hiddenMapAssetIds.includes(asset.id))
    },
    //To handle Reports Assets Selector
    hideReportAssetIds(assetIds) {
      this.hiddenReportAssetIds = this.hiddenReportAssetIds.filter(f => !assetIds.includes(f))
    },
    setReportHiddenAssetIds(assetIds) {
      this.hiddenReportAssetIds = assetIds
    },
    setReportAssetGroupByFilter(option) {
      this.ReportAssetGroupByFilter = option
    },
    assetsSelectedInReport() {
      return this.allAssets?.filter(asset => !this.hiddenReportAssetIds.includes(asset.id))
    },
    setAssetsWithUnknownData(assets) {
      this.assetsWithUnknownData = assets
    },
    getAssetsWithUnknownData() {
      return this.assetsWithUnknownData
    },
  },
})
