var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetHeaderStyled', [_c('header', [_vm._v(_vm._s(_vm.title) + " "), _vm.countLabel ? _c('CircleBadge', {
    attrs: {
      "label": _vm.countLabel,
      "size": 'M'
    }
  }) : _vm._e()], 1), _c('AssetDimensionTitle', {
    attrs: {
      "assetDimensions": _vm.assetDimensions
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }