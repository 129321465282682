<template>
  <AssetDimensionStyled>
    <div v-for="dimension in assetDimensions" :key="dimension.id">
      <span>{{ dimension.title }}</span>
      <ColoredTextStyled :color="dimension.color">{{
        dimension.value | localizeNumber(thousandsSeperator, decimalSeperator)
      }}</ColoredTextStyled>
      <span>{{ dimension.unit }}</span>
    </div>
  </AssetDimensionStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import round from '@/utils/round'
import localesMixin from '@/mixins/locales'
import localizeNumber from '@/utils/number-localized'

const AssetDimensionStyled = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  span {
    margin-right: 0.5rem;
  }
`
const ColoredTextStyled = styled('span')`
  color: ${p => p.color};
`

export default {
  mixins: [localesMixin],
  props: {
    assetDimensions: {
      required: true,
      type: Array,
    },
  },
  components: {
    AssetDimensionStyled,
    ColoredTextStyled,
  },
  filters: {
    round,
    localizeNumber,
  },
}
</script>
