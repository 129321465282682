var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionStyled', _vm._l(_vm.assetDimensions, function (dimension) {
    return _c('div', {
      key: dimension.id
    }, [_c('span', [_vm._v(_vm._s(dimension.title))]), _c('ColoredTextStyled', {
      attrs: {
        "color": dimension.color
      }
    }, [_vm._v(_vm._s(_vm._f("localizeNumber")(dimension.value, _vm.thousandsSeperator, _vm.decimalSeperator)))]), _c('span', [_vm._v(_vm._s(dimension.unit))])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }