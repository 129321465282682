<template>
  <WidgetHeaderStyled>
    <header>{{ title }} <CircleBadge :label="countLabel" v-if="countLabel" :size="'M'" /></header>
    <AssetDimensionTitle :assetDimensions="assetDimensions" />
  </WidgetHeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexColumns } from '@styles/mixins'
import AssetDimensionTitle from '@/components/Atomic/Atoms/AssetDimensionTitle'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
const WidgetHeaderStyled = styled('div')`
  ${flexColumns};
  color: ${p => p.theme.colors.panelHeader};
  padding: 0.5rem;
  gap: 0.5rem;
  grid-area: widgetHeader;

  > header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
  }
`
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    countLabel: {
      required: false,
      type: Number,
      default: 0,
    },
    assetDimensions: {
      required: false,
      type: Array,
    },
  },
  components: {
    WidgetHeaderStyled,
    CircleBadge,
    AssetDimensionTitle,
  },
}
</script>
